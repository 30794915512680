import React from 'react';
import classes from './CustomToast.module.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { getColor } from '../../utils/colors';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomToast = () => {
    const successColor = getColor('--brand-color-4');
    const errorColor = getColor('--brand-color-1');

    return (
        <Toaster
            position="top-center"
            toastOptions={{
                duration: 3000,
                success: {
                    iconTheme: {
                        primary: successColor,
                        secondary: 'white',
                    },
                },
                error: {
                    iconTheme: {
                        primary: errorColor,
                        secondary: 'white',
                    },
                },
            }}
        >
            {(t) => (
                <ToastBar toast={t}>
                    {({ icon, message }) => (
                        <div
                            className={classes.CloseButton}
                            onClick={() => toast.dismiss(t.id)}
                        >
                            {icon}
                            {message}
                            <FontAwesomeIcon
                                className={classes.Icon}
                                icon={faTimes}
                            />
                        </div>
                    )}
                </ToastBar>
            )}
        </Toaster>
    );
};

export default CustomToast;
