import React, { FunctionComponent } from 'react';
import TenantsManagement from '../Tenants/TenantsManagement/TenantsManagement';
import classes from './Home.module.scss';

const Home: FunctionComponent = () => {
    return (
        <div className={classes.Home}>
            <TenantsManagement />
        </div>
    );
};

export default Home;
