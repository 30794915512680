import React, { FunctionComponent, ReactNode } from 'react';
import classes from './NavigationIcon.module.scss';

type NavigationIconProps = {
    isSelected?: boolean;
    children?: ReactNode;
};

const NavigationIcon: FunctionComponent<NavigationIconProps> = ({ isSelected = false, children }) => {
    const isSelectedClass = isSelected ? classes.isSelected : '';

    return <div className={`icon ${classes.NavigationIcon} ${isSelectedClass}`}>{children}</div>;
};

export default NavigationIcon;
