import React, { FunctionComponent } from 'react';
// import { useConfig } from '../../context/ConfigContext';
// import { getFilePathByName, getFilePathForDefaultImage } from '../../models/File';
import classes from './Logo.module.scss';
import logoSrc from '../../assets/tenants/herocenter/logo.png';

const Logo: FunctionComponent = () => {
    // const { config, loadingConfig } = useConfig();
    // const [logoSrc, setLogoSrc] = useState('');

    // useEffect(() => {
    //     if (!loadingConfig) {
    //         const customFile = config.tenant.images['logo.png']?.find((file) => file.is_default == false);
    //         const defaultFile = config.tenant.images['logo.png']?.find((file) => file.is_default == true);
    //         const fileSrc =
    //             getFilePathByName(customFile?.file?.name) || getFilePathForDefaultImage(defaultFile?.file?.name) || '';
    //         setLogoSrc(fileSrc);
    //     }
    // }, [loadingConfig]);

    return (
        <img
            className={classes.LogoImage}
            // src={logoSrc}
            src={logoSrc}
            alt={`Logo HeroCenter`}
        ></img>
    );
};

export default Logo;
