import React from 'react';
import classes from './EmailSend.module.scss';
import { useTranslation } from 'react-i18next';
import BoxHeader from '../../../components/BoxHeader/BoxHeader';

const EmailSend = () => {
    const { t } = useTranslation();

    return (
        <div className={`Box ${classes.EmailSend}`}>
            <BoxHeader
                title={t('Auth:PASSWORD_REQUEST_TITLE')}
                subtitle={t('Auth:PASSWORD_REQUEST_CONFIRMATION')}
            ></BoxHeader>
        </div>
    );
};

export default React.memo(EmailSend);
