import React, { FunctionComponent, ReactNode } from 'react';
import Button from '../Button/Button';
import classes from './PageHeader.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

type Props = {
    title: string;
    subtitle?: string;
    underline?: boolean;
    bottomMargin?: boolean;
    rightAlignedText?: string;
    editHandler?: () => void;
    children?: ReactNode;
};

const PageHeader: FunctionComponent<Props> = ({
    title,
    subtitle,
    underline = true,
    bottomMargin = true,
    rightAlignedText,
    children,
    editHandler,
}) => {
    return (
        <div className={`${classes.Header} ${bottomMargin && classes.bottomMargin}`}>
            <div className={`${classes.Top} ${underline && classes.underline}`}>
                <h1 className={`h1 wrap-text ${classes.Title}`}>{title}</h1>
                {editHandler && (
                    <Button
                        className={classes.EditButton}
                        text=""
                        alt={false}
                        border={false}
                        icon={
                            <FontAwesomeIcon
                                className={classes.EditIcon}
                                icon={faPen}
                            />
                        }
                        onClick={editHandler}
                    ></Button>
                )}
            </div>
            <div className={classes.Bottom}>
                <span className={`wrap-text ${classes.Subtitle}`}>{subtitle}</span>
                {children}

                {rightAlignedText && <span className={`wrap-text ${classes.RightAlignedText}`}>{rightAlignedText}</span>}
            </div>
        </div>
    );
};

export default PageHeader;
