/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect } from 'react';

export const useDisableBodyScroll = (open: boolean) => {
    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = 'unset';
            document.body.style.height = 'initial';
        }
    }, [open]);
};
