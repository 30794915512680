import { faTrash, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import Button from '../../../../../components/Button/Button';
import { Tenant } from '../../../../../models/Tenant';
import classes from './TenantItem.module.scss';

interface TenantItemProps {
    tenant: Tenant;
    onRestoreClickHandler: (tenant: Tenant) => void;
    onDeleteClickHandler: (tenant: Tenant) => void;
}

const TenantItem: FunctionComponent<TenantItemProps> = ({ tenant, onRestoreClickHandler, onDeleteClickHandler }) => {
    return (
        <div
            key={`tenant_${tenant.id}`}
            className={classes.TenantItem}
        >
            <div className={`${classes.TenantsListItem} ${classes.TenantListId} wrap-text`}>{tenant.id}</div>
            <div className={`${classes.TenantsListItem} ${classes.TenantListName} wrap-text`}>{tenant.name}</div>

            <Button
                text=""
                icon={<FontAwesomeIcon icon={faTrashRestore} />}
                onClick={() => onRestoreClickHandler(tenant)}
                alert
            />
            <Button
                onClick={() => onDeleteClickHandler(tenant)}
                icon={<FontAwesomeIcon icon={faTrash} />}
                danger
            />
        </div>
    );
};

export default TenantItem;
