import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Logo from '../../components/Logo/Logo';
// import { useConfig } from '../../context/ConfigContext';
import classes from './ErrorPage.module.scss';

export interface Props {
    statusCode: number;
}

const ErrorPage: FunctionComponent<Props> = ({ statusCode }) => {
    // const { loading, config } = useConfig();
    const { t } = useTranslation();
    // const navigate = useNavigate();
    // const location = useLocation();

    // const goBackHandler = () => {
    //     navigate(location.pathname, { replace: true });
    // };

    return (
        <div className={classes.ErrorPage}>
            <h4>{statusCode}</h4>
            <h1>{t(`Common:ERROR_STATUSCODE_${statusCode}`)}</h1>

            <figure className={classes.Quote}>
                {/* <p>{t(`Common:ERROR_MESSAGE_QUOTE_START`)}</p> */}
                <q className="h2">A hero is anyone making this a better place for all people.</q>
                <figcaption>Maya Angelou</figcaption>
            </figure>

            {/* <Button text={t('Common:BUTTON_BACK')} alt={true} border={true} onClick={goBackHandler}></Button> */}
        </div>
    );
};

export default ErrorPage;
