import React, { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import TenantEditDetail from './TenantEditDetail/TenantEditDetail';
import TenantsManagement from './TenantsManagement/TenantsManagement';

const Tenants: FunctionComponent = () => {
    return (
        // <Route path="tenants">
        <PrivateRoute>
            <Routes>
                <Route
                    path="/"
                    element={<TenantsManagement />}
                />
                <Route
                    path=":tenantId"
                    element={<TenantEditDetail />}
                />
            </Routes>
        </PrivateRoute>

        // </Route>
    );
};

export default Tenants;
